@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}t

.markdown > * {
  all: revert;
}

ol {
  list-style: decimal;
  padding-left: 2rem;
}

:root{
  --primary-color:#0087c3; 
  --secondary-color: #50b848; 
  --tertiary-color: #f37021; 
  --gray-color: #333; 
}

::-webkit-scrollbar {
  width: 0px;
}

.loader {
  font-size: x-large;
  animation: spin 4s infinite linear;

}



@-webkit-keyframes spin {
  0%  {-webkit-transform: rotate(0deg);}
  100% {-webkit-transform: rotate(360deg);}   
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



