

.Lighter_Shade {
    background-color: rgba(72, 31, 255, 0.25);
}

.Lighter_Shade:hover{
    background-color: rgba(72, 31, 255, 0.45);
}

.Medium_Shade{
    background-color: rgba(72, 31, 255, 0.45);
}

.Medium_Shade:hover{
    background-color: rgba(72, 31, 255, 0.55);
}

.Darker_Shade{
    background-color: rgba(72, 31, 255, 0.85);
}

.Light_Purple_Shade {
    background-color: rgba(123, 90, 255, 0.55);
}

.Lighter_Purple_Shade{
    background-color: #F2F0FF;
    border-radius: 16px !important;
}

.Light_Gray{
    background-color: rgba(113, 128, 150, 0.11);
}

.Box_Shadow{
    box-shadow: 0 4px 10px rgba(113, 128, 150, 0.8);
}

.Gradient_Background {
    background: linear-gradient(90deg, #7BCBD4 0%, #29C6B7 100%);
  }
  

.Home, .Citations_Home{
    background: rgb(251,251,255);
    background: var(--background-gradient);
    max-height: 100vh;
    min-height: 100vh !important;
    overflow-y: hidden;
    padding-inline:10px;
    margin:0; 
    display: flex;
    flex-direction: row;
    width: 100%;
}

.Citations_Home{
    padding:0px;
}

.SideBar{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background: var(--background-gradient);
}

.Chats_Header_Icon{
    color: rgb(74,37,225);
    color: linear-gradient(81deg, rgba(74,37,225,1) 35%, rgba(102,67,242,1) 84%);
    font-size: 11px;
}
.Chats_Header_Icon_Div{
    height: 32px;
    width: 29px;
    background: rgb(251,251,255);
    border:2px solid rgb(74,37,225);
    transition: background 0.3s ease; 
   
}

.Chats_Header_Icon_Div:hover{
    background: rgb(238, 238, 239);
}
.Chats_Icon{
    color: #c6ccd5;
}
.ChatView_Chats{
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 10px;

}

.Chats{
    display: flex;
    flex-direction: column-reverse;
}
.Chat{
    display: flex;

}

.Chat_Name{
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    color: #aaa9a9;
    cursor: pointer;
}

.Selected_Chat{
    font-weight: 700;
    
}

.Citations_SideBar{
    width: 35%;
    padding-left: 20px;
}


.ChatView_Section, 
.SideBar_Citation_Section, 
.ChatView_Citation_Section,
.ChatDocView_Section
{
    padding-top: 5px;
    display: flex;
    flex-direction: column;
    
    
}
.SideBar_Citation_Section{
    padding-top: 15px;
    padding-inline:15px;
    overflow-y: scroll;
    position: relative;
    box-sizing: border-box;
}

 .SideBar_Citation_Header {
    min-height: 10vh;
    padding-inline: 10px;
    display: flex;
    align-items: center;
    gap: 6px;
    padding-bottom: 10px;
   border:1px solid red;
    padding-block: 20px;
}


.SideBar_Buttons{
    padding-inline: 10px;
    margin-top: auto; 
}

.SideBar_Buttons button{
    width: 100%;
    font-size: x-small;
    text-align: center;
}


.ChatView, .Citations_ChatView{
    margin: auto;
    width: 85%; 
    padding-inline: 20px;  
    padding-left: 30px; 
    min-height: 100vh;
    max-height: 100vh; 
    
   
}

.ChatView{
    height: 98vh;
}
.Citations_ChatView{
    width: 65%;
    padding-left: 0px; 
}

.ChatView_Section{
    height: 87%;
}

 .ChatView_Citation_Section{
    height: 100%;
    display: flex;
    flex-direction: column;
}

.ChatView_Header{
    padding-block: 11px;
    display: flex;
    justify-content: space-between;
    align-items: center;
   
}

.ChatView_Header p{
    font-size: 24px;
    color: var(--paragraph);
    font-weight: 600;
}


.NotCurrent_Buttons {
     box-shadow: rgba(33, 35, 38, 0.65) 0px 6px 10px -4px;
}


.ChatView_Icons_Container{
    width: 30%;
    border-radius: 45px;
    padding-inline: 10px;
    display: flex;
    justify-content: flex-end;
    gap:10px;
    box-shadow:rgba(33, 35, 38, 0.1) 0px 18px 18px -10px;
    color: #6b6b6b;
    
}

.ChatView_Icons_Container select{
    border-radius: 45px;
    background: rgb(74,37,225);
    background: linear-gradient(81deg, rgba(74,37,225,1) 35%, rgba(102,67,242,1) 84%);
    height: 40px; 
    width: auto; 
    padding: 0 20px; 
    border: none;
    color: white;
    display: flex; 
    align-items: center;
}

.ChatView_Icons_Container select option{
    color: #6b6b6b; 
}

.ChatView_Icons_Container select:focus{
    outline: none;
}



.Clicked_Chats{
   
    margin: auto;
    flex:1;
    overflow-y: auto;
    box-sizing: border-box;
    margin-bottom: 1px;
   
}

.ChatView_Citation_Citations{
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    
}

.Clicked_Chat{
    margin-inline: 10px;
    margin-block: 20px;
    
   
}

.Clicked_Chat:last-child{
    margin-bottom:1px;
}

.User, .Response_Icon{
    box-shadow: rgba(213, 217, 217, .5) 0 2px 5px 0;
}

.Prompt{
    box-shadow: rgba(213, 217, 217, .5) 0 2px 5px 0;
    color: var(--paragraph);
}
.Response{
    
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; 
    line-height:2;
    font-weight: 400;
    font-size: 13px;
}

.ChatView_Chat{
    flex:1;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    padding: 20px;
    border-radius: 16px;
    line-height: 1.8;
    color: #737373;
    color: var(--paragraph);
    height: auto;
}

.ChatView_Chat_Prompt{
    flex:1;
    padding: 20px;
    border-radius: 16px;
    line-height: 1.8;
    color: #737373;
    color: var(--paragraph);
    height: auto;
}



.ChatView_Footer form{
    margin-block: 10px;
    margin-bottom:0px;
    gap: 10px;
    box-sizing: border-box;
}

.ChatView_Footer form textarea {
    border-radius: 999px; 
    border-radius: 16px;
    box-sizing: border-box; 
  }

#Full_Border{
    border-radius: 999px; 
}

.ChatView_Footer form button{
    
    align-items: center;
    appearance: none;
    background-color: #fff;
    border-radius: 999px;
    border-style: none;
    box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px,rgba(0, 0, 0, .14) 0 6px 10px 0,rgba(0, 0, 0, .12) 0 1px 18px 0;
    box-sizing: border-box;
    color: white;
    font-size: 14px;
    font-weight: 500;
    height: 56px;
    justify-content: center;
    letter-spacing: .25px;
}


.Notifications_Modal .Delete_Chat{
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 16px;
    color: white; 
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    background-color: #800000;
    box-sizing: border-box;
    transition: background-color 300ms ease;
    font-size: 12px;

}

.Notifications_Modal .Delete_Chat_Icon{
    color: white; 
}


.Notifications_Modal .Delete_Chat:hover{
    background-color: #560000;
}



.Citation_Button{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px 0px;
}

.SideBar_Citation{
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
    background: rgb(240,242,251);
    background: linear-gradient(180deg, rgba(240,242,251,1) 45%, rgba(243,245,251,1) 67%, rgba(247,249,251,1) 88%);
}

.DocRepo_Section{
    padding-top: 20px;
}

.Search_Main_Container{
    margin-inline: 20px;
}

.Search_Buttons{
    display: flex;
    align-items: center;
    margin-top: 5px;
    padding-bottom: 20px;
    gap: 30px;
   
}

.Search_Buttons_Container {
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px 0px;
    border-radius: 24px; 
    width: 420px;
}

.Search_Buttons_Container button, .DocRepo_Section_Header .Back_Button {
    flex: 1;
    border: none;
    padding: 8px 20px;
    background-color: #cacaff;
    color: var(--paragraph);
    cursor: pointer;
}

.Search_Buttons_Container button.active {
    background-color: #fff; 
 
}

.Search_Buttons_Container button:first-child {
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px; 
    border-top-right-radius: 24px; 
    border-bottom-right-radius: 24px; 
}

.Search_Buttons_Container button:last-child {
    border-top-right-radius: 24px; 
    border-bottom-right-radius: 24px; 
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px; 
}

.Search_Container{
    margin-inline: 100px;
 
}

.Search_Form_Container{
    border-radius: 16px;
    padding: 10px 20px;
    background: rgb(240,242,251);
    background: linear-gradient(180deg, rgba(240,242,251,1) 45%, rgba(243,245,251,1) 67%, rgba(247,249,251,1) 88%);
}
.form-row {
    display: flex;
    margin-bottom: 8px;
    padding-block: 3px;
}

.form-row > div {
    flex: 1;

    display: flex;
    align-items: center;
}

.form-row input{
    border-radius: 16px;
    padding-block: 5px;
}

.form-row > div:last-child {
    margin-right: 0;
}

.Search_Form_Container .Search_Button, .DocRepo_Section_Result_Button, .DocRepo_Section_Header .Back_Button  {
    appearance: none;
    border-radius: 999px;
    border-style: none;
    box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px, rgba(0, 0, 0, .14) 0 6px 10px 0, rgba(0, 0, 0, .12) 0 1px 18px 0;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: .25px;
    height: 38px;
    padding-inline: 30px;
    letter-spacing: .25px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.Search_Form_Container .Search_Button:hover{
    background-color: #6b6b6b;
    color: #fff; 
}


.Search_Results_Container{
    margin-top: auto; 
  
}

.Search_Result{
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 16px; 
}

.Search_Result button{
    background: rgb(240,242,251);
    background: linear-gradient(180deg, rgba(240,242,251,1) 45%, rgba(243,245,251,1) 67%, rgba(247,249,251,1) 88%); 
    border-radius: 16px; 
    transition: background-color 0.3s ease;
}

.Search_Result button:hover{
    background: rgb(175,176,181);
    background: linear-gradient(180deg, rgba(175,176,181,1) 17%, rgba(208,210,217,1) 39%, rgba(220,222,229,1) 61%, rgba(229,231,239,1) 79%, rgba(240,242,251,0.01) 100%);
}

.DocRepo_Section_Header{
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
      
}


.Verify_Email_Container{
    background: rgb(251,251,255);
    background: var(--background-gradient);

}

.Verify_Email_Container.Verify_Container{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 16px;
}

.Chat_Documents{
    height: auto; 
    overflow-y: auto;
    padding: 10px;
}

.Chat_Document{
    padding: 10px;
    display: flex;
    justify-content: space-between;

}

.Chat_Document_Title, .Chat_Document_Button{
    width: 75%;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 45px;
    padding: 15px;
    padding-inline: 20px;
}

.Chat_Document_Button {
    width: 20%; 
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(240,242,251);
    background: linear-gradient(180deg, rgba(240,242,251,1) 45%, rgba(243,245,251,1) 67%, rgba(247,249,251,1) 88%);
}

.Side_Buttons {
    background: rgb(240, 242, 251); 
    background: linear-gradient(
      180deg,
      rgba(235, 225, 255, 1) 45%, 
      rgba(240, 230, 255, 1) 67%,
      rgba(245, 235, 255, 1) 88% 
    );
  }
  
  

.Chat_Document_Button button {
    color: #6b6b6b;  
}

.Chat_Document_Button:hover {
    background: linear-gradient(180deg, rgba(220, 222, 231, 0.9) 45%, rgba(223, 225, 231, 0.9) 67%, rgba(227, 229, 231, 0.9) 88%);
    transition: background-color 300ms ease;
}

.Add_Doc_Container {
    margin-block: 10px;
    margin-inline: auto;
}

.Notifications_Container {
    text-align: center;
}

.Error_Container, .Success_Container {
    border-radius: 12px;
    padding-inline: 20px;
    padding-block: 10px;
    margin-block: 10px;
}

.Success_Container {
    background-color: #c9fbd5;
    color: green;
}

.Error_Container {
    background-color: #fde0e0;
    color: red;
}

.Add_Doc {
    width: 90%; 
    margin: auto;
    height: 75vh;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}

.Add_Doc button {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 45px;
    padding: 10px;
    padding-inline: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #3a9c9a;
    color: white;
    font-weight: 600;
    transition: background-color 300ms ease;
}

.Add_Doc button:hover {
    background-color: #267270;
}


.Notifications_Modal{
    position: absolute;
    top: 41%;
    left: 16%;
    height: 140px;
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}



.Side_Chats::-webkit-scrollbar {
    width: 2px; 
  }
  
  .Side_Chats::-webkit-scrollbar-track {
    background: white; 
  }
  
  .Side_Chats::-webkit-scrollbar-thumb {
    background: white; 
  }
  
  .Side_Chats::-webkit-scrollbar-thumb:hover {
    background: #e5e4e4; 
  }




/* Media query for screens with a width of 768px and below */
@media (max-width: 768px) {
    
    .Home, .Citations_Home {
        flex-direction: column; 
    }
    
    /*.SideBar {
        width: 100%; 
        border-radius: 0; 
        box-shadow: none; 
    }*/

    .ChatView_Header{
       flex-direction: column;
       justify-content: center;
         
    }

    .ChatView_Icons_Container{
        width: 100%;
        justify-content: space-between;
      
    }
    
    .ChatView, .Citations_ChatView {
        width: 100%; 
        padding: 10px; 
        padding-left: 0; 
    }

    .ChatView_Chats{
        flex-direction: column;
    }

    .Clicked_Chats {
        width: 100%; 
        padding: 10px; 
    }
    
    .ChatView_Footer {
        width: 100%; 
        padding: 10px; 
    }
}
.Blob{
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70% ;
}

.Border_Gradient {
    background: linear-gradient(to right, #6ddcff, #7f60f9);
  }
  

.list{
    max-width: 450px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding:0;
    list-style: none;
   /* background-color: #fafafa;*/
    --sentColor: #0b93f6;
    --receiveColor: #e5e5ea;
}


.received {
    align-self: flex-start;
    color: #000;
    background: var(--receiveColor);
}
.sent {
    align-self: flex-end;
    color: #fff;
    background: var(--sentColor);
}
.noTail {
    margin-bottom: 2px;
}
.shared {
    position: relative;
    max-width: 255px;
    margin-bottom: 15px;
    padding: 10px 20px;
    line-height: 24px;
    word-wrap: break-word;
    white-space: pre-wrap;
    border-radius: 25px;
}

@keyframes listUpwardMotion {
    0%{
        transform: translateY(400px);
    }
    
    60%, 80%{
        transform: translateY(-5px);
    }

    70%{
       transform: translateY(2px); 
    }


    90%,100%{
        transform: translateY(0%);
    }
}